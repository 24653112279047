export enum Path {
  ROOT = "/",
  LIST = "/list",
  CREATE = "/create",
  EDIT = "/edit",
  ADMIN = "/admin",
  LOGIN = "/login",
}

export interface ISheet {
  sheetId: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  statuses: IStatus[] | undefined;
}

export type GenericMap<T> = {
  [key: number]: T;
};

export type SheetsMap = {
  [sheetName: string]: ISheet;
};

export interface IStatus {
  statusId: number;
  name: string;
  sheetId: number;
  derivations: number[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IStatusDerivation {
  statusId: number;
  derivableStatusId: number;
}

export interface IRoleVisibleSheet {
  roleId: number;
  sheetId: number;
}

export interface IRole {
  roleId: number;
  name: string;
  onlyOwnRecords: boolean;
  editOnly: boolean;
  visibleColumns: string | string[];
  editableColumns: string | string[];
  visibleSheets: number[];
  createdAt: Date;
  updatedAt: Date;
}

export type AdminEntities = {
  roles: IRole[];
  sheets: ISheet[];
  statuses: IStatus[];
};

export interface IPublicUser {
  userId: number;
  name: string;
  lastName: string;
  picture: string;
}

export interface IUser extends IPublicUser {
  userId: number;
  name: string;
  lastName: string;
  email: string;
  phone: string;
  govId: string;
  group: string;
  roleId: number;
  active: boolean;
  inactiveSince: Date;
  createdAt: Date;
  updatedAt: Date;
  role: IRole;
}

export interface FilterEntry {
  value: number;
  label: string;
}

export interface IBusiness {
  // Always present
  id: number;
  statusId: number;
  creatorId: number;
  lastEditorId: number;
  createdAt: Date;
  updatedAt: Date;
  status: IStatus;
  creator: IPublicUser;
  lastEditor: IPublicUser;
  // Specific to business
  verificadorId: number | null;
  verificador: IPublicUser | null;
  auditorId: number | null;
  auditor: IPublicUser | null;
  nro_op: number | null;
  producto: string | null;
  megas: string | null;
  promo: string | null;
  nro_cliente: string | null;
  nombre: string;
  apellido: string;
  dni: string;
  fecha_nacimiento: Date | null;
  tel_contacto: string | null;
  nombre_contacto: string | null;
  dni_contacto: string | null;
  email_contacto: string | null;
  calle: string | null;
  altura: number | null;
  depto: string | null;
  entre_calles: string | null;
  localidad: string | null;
  mz: number | null;
  central: number | null;
  dir_instalacion: string | null;
  score: number;
  rp: string | null;
  cita: Date | null;
  cbu: string | null;
  modo_pago: string | null;
  bo: string | null;
  totalizacion: string | null;
  fecha_audicion: Date | null;
  ani: string | null;
  observaciones: string | null;
  observaciones_verificador: string | null;
  observaciones_auditor: string | null;
  carga: string | null;
  fecha_carga: Date | null;
  estado_carga: string | null;
  nro_oms: number | null;
  nro_orden: string | null;
  fecha_cump: Date | null;
  informe_toa: string | null;
  fecha_sat: Date | null;
  porcentage: number | null;
  tel_servicio: string | null;
  recurso: string | null;
  linea: string | null;
}

// Changing the order of this
// will change the displayed columns order
export enum BusinessColumns {
  // Always present
  ID = "id",
  STATUS_ID = "statusId",
  CREATOR_ID = "creatorId",
  LAST_EDITOR_ID = "lastEditorId",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  STATUS = "status",
  CREATOR = "creator",
  EDITOR = "lastEditor",
  // Specific to the business
  VERIFICADOR_ID = "verificadorId",
  VERIFICADOR = "verificador",
  AUDITOR_ID = "auditorId",
  AUDITOR = "auditor",
  NRO_OP = "nro_op",
  PRODUCTO = "producto",
  MEGAS = "megas",
  PROMO = "promo",
  NRO_CLIENTE = "nro_cliente",
  NOMBRE = "nombre",
  APELLIDO = "apellido",
  DNI = "dni",
  FECHA_NACIMIENTO = "fecha_nacimiento",
  TEL_CONTACTO = "tel_contacto",
  NOMBRE_CONTACTO = "nombre_contacto",
  DNI_CONTACTO = "dni_contacto",
  EMAIL_CONTACTO = "email_contacto",
  CALLE = "calle",
  ALTURA = "altura",
  DEPTO = "depto",
  ENTRE_CALLES = "entre_calles",
  LOCALIDAD = "localidad",
  MZ = "mz",
  CENTRAL = "central",
  DIR_INSTALACION = "dir_instalacion",
  SCORE = "score",
  RP = "rp",
  CITA = "cita",
  CBU = "cbu",
  MODO_PAGO = "modo_pago",
  BO = "bo",
  TOTALIZACION = "totalizacion",
  FECHA_AUDICION = "fecha_audicion",
  ANI = "ani",
  OBSERVACIONES = "observaciones",
  OBSERVACIONES_VERIFICADOR = "observaciones_verificador",
  OBSERVACIONES_AUDITOR = "observaciones_auditor",
  CARGA = "carga",
  FECHA_CARGA = "fecha_carga",
  ESTADO_CARGA = "estado_carga",
  NRO_OMS = "nro_oms",
  NRO_ORDEN = "nro_orden",
  FECHA_CUMP = "fecha_cump",
  INFORME_TOA = "informe_toa",
  FECHA_SAT = "fecha_sat",
  PORCENTAGE = "porcentage",
  TEL_SERVICIO = "tel_servicio",
  RECURSO = "recurso",
  LINEA = "linea",
}

export const alwaysVisibleBusinessCols = [
  BusinessColumns.CREATED_AT,
  BusinessColumns.UPDATED_AT,
  BusinessColumns.CREATOR,
  BusinessColumns.EDITOR,
  BusinessColumns.STATUS_ID,
];

// ATM business columns have 'auditor' & 'verificador'
// which refer to users with that role
// we'll need to fetch the users for these roles for the dropdown menu
// in the form
export const userRoleColumns = [
  BusinessColumns.VERIFICADOR,
  BusinessColumns.AUDITOR,
];

// this columns should be hidded
// cause there's another column used
// for showing the data properly
export const hiddedColumns = [
  // always hidden
  BusinessColumns.ID,
  BusinessColumns.CREATOR_ID,
  BusinessColumns.LAST_EDITOR_ID,
  BusinessColumns.STATUS,

  // Business specific
  BusinessColumns.VERIFICADOR_ID,
  BusinessColumns.AUDITOR_ID,
];

// These are the columns that can only be edited
// e.i, should not be included on record creation
// because they have default value defined (e.g. default statusId)
// or the business logic requires to be this way
export const onlyEditableCols = [BusinessColumns.STATUS_ID, ...userRoleColumns];

export enum UserColumns {
  ID = "userId",
  NAME = "name",
  LAST_NAME = "lastName",
  EMAIL = "email",
  PICTURE = "picture",
  PHONE = "phone",
  GOV_ID = "govId",
  GROUP = "group",
  ROLE_ID = "roleId",
  ACTIVE = "active",
  INACTIVE_SINCE = "inactiveSince",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  ROLE = "role",
}

// Columns to display a certain user information,
// e.g., show the creators info of a bussiness record
export const userMetaColumns = [
  UserColumns.NAME,
  UserColumns.LAST_NAME,
  UserColumns.GOV_ID,
  UserColumns.GROUP,
  UserColumns.PHONE,
];

export enum SheetColumns {
  ID = "sheetId",
  NAME = "name",
  STATUSES = "statuses",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
}

export enum StatusColumns {
  ID = "statusId",
  NAME = "name",
  SHEET_ID = "sheetId",
  DERIVATIONS = "derivations",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
}

export enum RoleColumns {
  ID = "roleId",
  NAME = "name",
  VISIBLE_COLUMNS = "visibleColumns",
  EDITABLE_COLUMNS = "editableColumns",
  VISIBLE_SHEETS = "visibleSheets",
  ONLY_OWN_RECORDS = "onlyOwnRecords",
  EDIT_ONLY = "editOnly",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
}

// ==========================
//     ADMIN section types
// ==========================

import usersApi from "../services/api/users";
import rolesApi from "../services/api/roles";
import statusApi from "../services/api/status";
import sheetsApi from "../services/api/sheets";

export const ADMIN_ROLE_ID = 1;
export const ADMIN_ROLE: Partial<IRole> = {
  name: "admin",
  roleId: ADMIN_ROLE_ID,
  visibleColumns: "*",
  editableColumns: "*",
};

export enum AdminSection {
  Users = "users",
  Roles = "roles",
  Status = "status",
  Sheets = "sheets",
}

export type AdminEntity = IUser | IStatus | IRole | ISheet;

export const AdminSectionIdCol = {
  [AdminSection.Users]: UserColumns.ID,
  [AdminSection.Roles]: RoleColumns.ID,
  [AdminSection.Sheets]: SheetColumns.ID,
  [AdminSection.Status]: StatusColumns.ID,
};

export const AdminSectionCols = {
  [AdminSection.Users]: Object.values(UserColumns),
  [AdminSection.Roles]: Object.values(RoleColumns),
  [AdminSection.Sheets]: Object.values(SheetColumns),
  [AdminSection.Status]: Object.values(StatusColumns),
};

export const apiServices = {
  users: usersApi,
  roles: rolesApi,
  status: statusApi,
  sheets: sheetsApi,
};

// ==========================
//      Sorting & Filter
// ==========================

export type SortingField =
  | BusinessColumns.CREATED_AT
  | BusinessColumns.UPDATED_AT;

export type Order = "asc" | "desc";

// user defined type guard
export function isSortingField(field: string): field is SortingField {
  return (
    [BusinessColumns.CREATED_AT, BusinessColumns.UPDATED_AT].indexOf(
      field as BusinessColumns
    ) !== -1
  );
}

export const UserFilterField = [
  BusinessColumns.CREATOR,
  BusinessColumns.EDITOR,

  // Business Specific
  BusinessColumns.VERIFICADOR,
  BusinessColumns.AUDITOR,
];

export const FilterField = [
  BusinessColumns.CREATED_AT,
  BusinessColumns.UPDATED_AT,
  ...UserFilterField,
];

// QuerySearchFields is an array of fields
// that will be checked when a search query is defined
// Filtering is done in the front end at the moment
export const QuerySearchFields = [UserColumns.NAME, UserColumns.LAST_NAME];

// AdminSectionFilterField is an array of the columns
// in an AdminSection that can be filtered
export const AdminSectionFilterField = [UserColumns.ROLE];

export function isFilterField(field: string): boolean {
  return FilterField.indexOf(field as BusinessColumns) !== -1;
}

export function isSortingAndFilterField(field: string): boolean {
  return isSortingField(field) && isFilterField(field);
}

export const monthOptions = [
  { value: "0", label: "January" },
  { value: "1", label: "February" },
  { value: "2", label: "March" },
  { value: "3", label: "April" },
  { value: "4", label: "May" },
  { value: "5", label: "June" },
  { value: "6", label: "July" },
  { value: "7", label: "August" },
  { value: "8", label: "September" },
  { value: "9", label: "October" },
  { value: "10", label: "November" },
  { value: "11", label: "December" },
];

// =============================
//          WS Events
// =============================

export enum Events {
  BusinessCreated = "BusinessCreated",
  BusinessUpdated = "BusinessUpdated",
  BusinessSheetChange = "BusinessSheetChange",
  BusinessDeleted = "BusinessDeleted",

  SheetCreated = "SheetCreated",
  SheetUpdated = "SheetUpdated",
  SheetDeleted = "SheetDeleted",

  StatusCreated = "StatusCreated",
  StatusUpdated = "StatusUpdated",
  StatusDeleted = "StatusDeleted",
  StatusDerivationsUpdated = "StatusDerivationsUpdated",

  RoleCreated = "RoleCreated",
  RoleUpdated = "RoleUpdated",
  RoleDeleted = "RoleDeleted",
  RoleVisibleSheetsUpdated = "RoleVisibleSheetsUpdated",

  UserDeactivated = "UserDeactivated",
}
