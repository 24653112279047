import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Paper, Typography } from "@material-ui/core";
import {
  businessValidationSchema,
  businessInitialValues,
  businessFields,
  INITIAL_STATUS_ID,
} from "../components/form/business";
import {
  AdminSection,
  BusinessColumns,
  GenericMap,
  IBusiness,
  IRole,
  ISheet,
  Path,
  onlyEditableCols,
} from "../types";
import businessApi from "../services/api/business";
import { defaultSeparator } from "../utils";
import { parseNameToPath } from "../utils/formatters";
import GoBackButton from "../components/btn/GoBackButton";
import SaveButton from "../components/btn/SaveButton";
import ButtonContainer from "../components/btn/ButtonContainer";
import { formStyles } from "../components/form";
import { SnackbarAction } from "../components/form/SuccessSnackbar";
import { lang } from "../lang";
import BasePage from "../components/page/BasePage";
import { getStoredItem } from "../state/localstorage";

const useStyles = formStyles;

const CreatePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const visibleSheets = getStoredItem(
    AdminSection.Sheets
  ) as GenericMap<ISheet>;
  const role = getStoredItem("role") as IRole;

  // current logic only allows to create on status 1
  const tableName = visibleSheets[INITIAL_STATUS_ID].name;
  const tablePath = parseNameToPath(tableName);
  let editableCols = (role.editableColumns as string).split(defaultSeparator);

  if (role.editableColumns == "*") {
    editableCols = Object.keys(businessFields);
  }

  const formik = useFormik({
    initialValues: businessInitialValues as IBusiness,
    validationSchema: businessValidationSchema,
    onSubmit: (values: IBusiness) => {
      handleSubmit(values as IBusiness);
    },
  });

  const handleSubmit = async (values: IBusiness) => {
    try {
      await businessApi.create(values);
      // current logic only allows to create on sheet 1
      // so return to that sheet once the operation was successful
      navigate(`${Path.LIST}/${tablePath}`, {
        state: {
          sheetId: INITIAL_STATUS_ID,
          sheet: visibleSheets[INITIAL_STATUS_ID],
          tableName,
          role,
          visibleSheets: visibleSheets,
          successfulOp: true,
          opAction: SnackbarAction.CREATE,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error.response?.data?.message);
      setError(error.response?.data?.message || lang("UnexpectedError"));
    }
  };

  return (
    <BasePage role={role} sheetsMap={visibleSheets} addBtn={false}>
      <Paper className={classes.root}>
        <Typography variant="h6" id="formTitle" component="div">
          {`${lang("CreateRecord")} ${tableName}`}
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          {editableCols.map((c: string) => {
            // For current business logic, no need the user role columns on creation
            // cause verificador and auditor are only used on edition
            // Same for statusId, it will be created with the initial statusId
            // by default
            if (onlyEditableCols.includes(c as BusinessColumns)) {
              return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            return businessFields[c](formik);
          })}
          <ButtonContainer>
            <SaveButton key={"create-btn-1"} />
            <GoBackButton key={"create-btn-2"} />
          </ButtonContainer>
        </form>
      </Paper>
    </BasePage>
  );
};

export default CreatePage;
