import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import ApprovalIcon from "@mui/icons-material/Approval";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import DescriptionIcon from "@mui/icons-material/Description";
import { AdminSection } from "../../types";

export const AdminSectionIcons = {
  [AdminSection.Users]: (style?: any) => <GroupIcon sx={style} />,
  [AdminSection.Roles]: (style?: any) => <PlaylistAddCheckIcon sx={style} />,
  [AdminSection.Status]: (style?: any) => <ApprovalIcon sx={style} />,
  [AdminSection.Sheets]: (style?: any) => <DescriptionIcon sx={style} />,
};
