import React from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import { FormikValues } from "formik";
import { MobileDatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import { BusinessColumns, IBusiness, IPublicUser } from "../../types";
import FormikBusinessTextField from "./FormikBusinessTextField";
import FormikDrowdownEntity, { DropdownOption } from "./FormikDropdownEntity";
import { lang } from "../../lang";

export const INITIAL_STATUS_ID = 1;

export const businessInitialValues: Partial<IBusiness> = {
  auditorId: null,
  verificadorId: null,
  nro_op: null,
  producto: "",
  megas: "",
  promo: "",
  nro_cliente: "",
  nombre: "",
  apellido: "",
  dni: "",
  fecha_nacimiento: null,
  tel_contacto: "",
  nombre_contacto: "",
  dni_contacto: "",
  email_contacto: "",
  calle: "",
  altura: null,
  depto: "",
  entre_calles: "",
  localidad: "",
  mz: null,
  central: null,
  dir_instalacion: "",
  score: 0,
  rp: "",
  cita: null,
  cbu: "",
  modo_pago: "",
  bo: "",
  totalizacion: "",
  fecha_audicion: null,
  ani: "",
  observaciones: "",
  observaciones_verificador: "",
  observaciones_auditor: "",
  carga: "",
  fecha_carga: null,
  estado_carga: "",
  nro_oms: null,
  nro_orden: "",
  fecha_cump: null,
  informe_toa: "",
  fecha_sat: null,
  porcentage: null,
  tel_servicio: "",
  recurso: "",
  linea: "",
  statusId: INITIAL_STATUS_ID,
};

// Based on role
// TODO should allow only to edit editable fields
// define validation schema
export const businessValidationSchema = Yup.object({
  auditorId: Yup.number().nullable(),
  verificadorId: Yup.number().nullable(),
  nro_op: Yup.number().nullable(),
  producto: Yup.string().nullable(),
  megas: Yup.string().nullable(),
  promo: Yup.string().nullable(),
  nro_cliente: Yup.string().nullable(),
  nombre: Yup.string().required(),
  apellido: Yup.string().required(),
  dni: Yup.string().required(),
  fecha_nacimiento: Yup.date().required(),
  tel_contacto: Yup.string().nullable(),
  nombre_contacto: Yup.string().nullable(),
  dni_contacto: Yup.string().nullable(),
  email_contacto: Yup.string().email("Invalid email format").nullable(),
  calle: Yup.string().nullable(),
  altura: Yup.number().nullable(),
  depto: Yup.string().nullable(),
  entre_calles: Yup.string().nullable(),
  localidad: Yup.string().nullable(),
  mz: Yup.number().nullable(),
  central: Yup.number().nullable(),
  dir_instalacion: Yup.string().nullable(),
  score: Yup.number().nullable(),
  rp: Yup.string().nullable(),
  cita: Yup.date().nullable(),
  cbu: Yup.string().nullable(),
  modo_pago: Yup.string().nullable(),
  bo: Yup.string().nullable(),
  totalizacion: Yup.string().nullable(),
  fecha_audicion: Yup.date().nullable(),
  ani: Yup.string().nullable(),
  observaciones: Yup.string().nullable(),
  observaciones_verificador: Yup.string().nullable(),
  observaciones_auditor: Yup.string().nullable(),
  carga: Yup.string().nullable(),
  fecha_carga: Yup.date().nullable(),
  estado_carga: Yup.string().nullable(),
  nro_oms: Yup.number().nullable(),
  nro_orden: Yup.string().nullable(),
  fecha_cump: Yup.date().nullable(),
  informe_toa: Yup.string().nullable(),
  fecha_sat: Yup.date().nullable(),
  porcentage: Yup.number().nullable(),
  tel_servicio: Yup.string().nullable(),
  recurso: Yup.string().nullable(),
  linea: Yup.string().nullable(),
});

// usersByRole is used if there's a business column
// that refers to a role. So the users by role will be
// used for a Dropdown field in the form
interface FieldData {
  statusOptions: DropdownOption[];
  usersByRole: { [key: string]: IPublicUser[] };
}

export const businessFields = {
  [BusinessColumns.STATUS_ID]: (
    formik: FormikValues,
    data: FieldData,
    classes: any
  ) => (
    <FormikDrowdownEntity
        key="ddn-stat-1"
        formik={formik}
      field={BusinessColumns.STATUS_ID}
      label={lang("Status")}
      options={data.statusOptions}
      classes={classes}
    />
  ),
  [BusinessColumns.VERIFICADOR]: (
    formik: FormikValues,
    data: FieldData,
    classes: any
  ) => {
    let options: DropdownOption[] = [];
    const users = data.usersByRole[BusinessColumns.VERIFICADOR];
    if (!users || !users.length) {
      options = [];
    } else {
      options = data.usersByRole[BusinessColumns.VERIFICADOR].map(
        (u: IPublicUser) => ({
          value: u.userId,
          label: `${u.lastName}, ${u.name}`,
        })
      );
    }
    return (
      <FormikDrowdownEntity
        key="ddn-verif-1"
        formik={formik}
        field={`${BusinessColumns.VERIFICADOR}Id`}
        label="Verificador"
        options={options}
        classes={classes}
      />
    );
  },
  [BusinessColumns.AUDITOR]: (
    formik: FormikValues,
    data: FieldData,
    classes: any
  ) => {
    let options: DropdownOption[] = [];
    const users = data.usersByRole[BusinessColumns.AUDITOR];
    if (!users || !users.length) {
      options = [];
    } else {
      options = data.usersByRole[BusinessColumns.AUDITOR].map((u: IPublicUser) => ({
        value: u.userId,
        label: `${u.lastName}, ${u.name}`,
      }));
    }
    return (
      <FormikDrowdownEntity
        key="ddn-auditor-1"
        formik={formik}
        field={`${BusinessColumns.AUDITOR}Id`}
        label="Auditor"
        options={options}
        classes={classes}
      />
    );
  },
  [BusinessColumns.NRO_OP]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-nro-op-1"
      formik={formik}
      fieldName={BusinessColumns.NRO_OP}
      label="Nro Op"
      type="number"
    />
  ),
  [BusinessColumns.PRODUCTO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-prod-1"
      formik={formik}
      fieldName={BusinessColumns.PRODUCTO}
      label="Producto"
      type="text"
    />
  ),
  [BusinessColumns.MEGAS]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-megas-1"
      formik={formik}
      fieldName={BusinessColumns.MEGAS}
      label="Megas"
      type="text"
    />
  ),
  [BusinessColumns.PROMO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-promo-1"
      formik={formik}
      fieldName={BusinessColumns.PROMO}
      label="Promo"
      type="text"
    />
  ),
  [BusinessColumns.NRO_CLIENTE]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-nro-cl-1"
      formik={formik}
      fieldName={BusinessColumns.NRO_CLIENTE}
      label="Nro Cliente"
      type="text"
    />
  ),
  [BusinessColumns.NOMBRE]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-nombre-1"
      formik={formik}
      fieldName={BusinessColumns.NOMBRE}
      label="Nombre"
      type="text"
    />
  ),
  [BusinessColumns.APELLIDO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-apellido-1"
      formik={formik}
      fieldName={BusinessColumns.APELLIDO}
      label="Apellido"
      type="text"
    />
  ),
  [BusinessColumns.DNI]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-dni-1"
      formik={formik}
      fieldName={BusinessColumns.DNI}
      label="DNI"
      type="text"
    />
  ),
  [BusinessColumns.FECHA_NACIMIENTO]: (formik: FormikValues) => (
    <MobileDatePicker
      label="Fecha Nacimiento"
      key="text-fch-nac-1"
      sx={{ minWidth: "100%" }}
      format="DD/MM/YYYY"
      defaultValue={dayjs(formik.values[BusinessColumns.FECHA_NACIMIENTO])}
      onChange={(v) =>
        formik.setFieldValue([BusinessColumns.FECHA_NACIMIENTO], v, true)
      }
    />
  ),
  [BusinessColumns.TEL_CONTACTO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-tel-cto-1"
      formik={formik}
      fieldName={BusinessColumns.TEL_CONTACTO}
      label="Tel Contacto"
      type="text"
    />
  ),
  [BusinessColumns.NOMBRE_CONTACTO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-nom-cto-1"
      formik={formik}
      fieldName={BusinessColumns.NOMBRE_CONTACTO}
      label="Nombre Contacto"
      type="text"
    />
  ),
  [BusinessColumns.DNI_CONTACTO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-dni-cto-1"
      formik={formik}
      fieldName={BusinessColumns.DNI_CONTACTO}
      label="DNI Contacto"
      type="text"
    />
  ),
  [BusinessColumns.EMAIL_CONTACTO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-email-cto-1"
      formik={formik}
      fieldName={BusinessColumns.EMAIL_CONTACTO}
      label="Email Contacto"
      type="email"
    />
  ),
  [BusinessColumns.CALLE]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-calle-1"
      formik={formik}
      fieldName={BusinessColumns.CALLE}
      label="Calle"
      type="text"
    />
  ),
  [BusinessColumns.ALTURA]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-altura-1"
      formik={formik}
      fieldName={BusinessColumns.ALTURA}
      label="Altura"
      type="number"
    />
  ),
  [BusinessColumns.DEPTO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-dpto-1"
      formik={formik}
      fieldName={BusinessColumns.DEPTO}
      label="Dpto"
      type="text"
    />
  ),
  [BusinessColumns.ENTRE_CALLES]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-e-calles-1"
      formik={formik}
      fieldName={BusinessColumns.ENTRE_CALLES}
      label="Entre calles"
      type="text"
    />
  ),
  [BusinessColumns.LOCALIDAD]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-loc-1"
      formik={formik}
      fieldName={BusinessColumns.LOCALIDAD}
      label="Localidad"
      type="text"
    />
  ),
  [BusinessColumns.MZ]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-mz-1"
      formik={formik}
      fieldName={BusinessColumns.MZ}
      label="Mz"
      type="number"
    />
  ),
  [BusinessColumns.CENTRAL]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-central-1"
      formik={formik}
      fieldName={BusinessColumns.CENTRAL}
      label="Central"
      type="number"
    />
  ),
  [BusinessColumns.DIR_INSTALACION]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-dir-inst-1"
      formik={formik}
      fieldName={BusinessColumns.DIR_INSTALACION}
      label="Dir Instalacion"
      type="text"
    />
  ),
  [BusinessColumns.SCORE]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-score-1"
      formik={formik}
      fieldName={BusinessColumns.SCORE}
      label="Score"
      type="number"
    />
  ),
  [BusinessColumns.RP]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-rp-1"
      formik={formik}
      fieldName={BusinessColumns.RP}
      label="RP"
      type="text"
    />
  ),
  [BusinessColumns.CITA]: (formik: FormikValues) => (
    <MobileDateTimePicker
      label="Cita"
      format="DD/MM/YYYY HH:mm"
      sx={{ minWidth: "100%" }}
      key="text-ct-d-1"
      defaultValue={dayjs(formik.values[BusinessColumns.CITA])}
      onChange={(v) => formik.setFieldValue([BusinessColumns.CITA], v, true)}
    />
  ),
  [BusinessColumns.CBU]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-cbu-1"
      formik={formik}
      fieldName={BusinessColumns.CBU}
      label="CBU"
      type="text"
    />
  ),
  [BusinessColumns.MODO_PAGO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-modo-pago-1"
      formik={formik}
      fieldName={BusinessColumns.MODO_PAGO}
      label="Modo pago"
      type="text"
    />
  ),
  [BusinessColumns.BO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-bo-1"
      formik={formik}
      fieldName={BusinessColumns.BO}
      label="Bo"
      type="text"
    />
  ),
  [BusinessColumns.TOTALIZACION]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-tot-1"
      formik={formik}
      fieldName={BusinessColumns.TOTALIZACION}
      label="Totalizacion"
      type="text"
    />
  ),
  [BusinessColumns.FECHA_AUDICION]: (formik: FormikValues) => (
    <MobileDatePicker
      label="Fecha audicion"
      key="text-fa-1"
      sx={{ minWidth: "100%" }}
      format="DD/MM/YYYY"
      defaultValue={dayjs(formik.values[BusinessColumns.FECHA_AUDICION])}
      onChange={(v) =>
        formik.setFieldValue([BusinessColumns.FECHA_AUDICION], v, true)
      }
    />
  ),
  [BusinessColumns.ANI]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-ani-1"
      formik={formik}
      fieldName={BusinessColumns.ANI}
      label="Ani"
      type="text"
    />
  ),
  [BusinessColumns.OBSERVACIONES]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-obs-1"
      formik={formik}
      fieldName={BusinessColumns.OBSERVACIONES}
      label="Observaciones"
      type="text"
      rows={3}
    />
  ),
  [BusinessColumns.OBSERVACIONES_VERIFICADOR]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-obs-verif-1"
      formik={formik}
      fieldName={BusinessColumns.OBSERVACIONES_VERIFICADOR}
      label="Observaciones verificador"
      type="text"
      rows={3}
    />
  ),
  [BusinessColumns.OBSERVACIONES_AUDITOR]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-obs-auditor-1"
      formik={formik}
      fieldName={BusinessColumns.OBSERVACIONES_AUDITOR}
      label="Observaciones auditor"
      type="text"
      rows={3}
    />
  ),
  [BusinessColumns.CARGA]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-carga-1"
      formik={formik}
      fieldName={BusinessColumns.CARGA}
      label="Carga"
      type="text"
    />
  ),
  [BusinessColumns.FECHA_CARGA]: (formik: FormikValues) => (
    <MobileDatePicker
      label="Fecha carga"
      format="DD/MM/YYYY"
      sx={{ minWidth: "100%" }}
      key="text-f-carg-1"
      defaultValue={dayjs(formik.values[BusinessColumns.FECHA_CARGA])}
      onChange={(v) =>
        formik.setFieldValue([BusinessColumns.FECHA_CARGA], v, true)
      }
    />
  ),
  [BusinessColumns.ESTADO_CARGA]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-est-carga-1"
      formik={formik}
      fieldName={BusinessColumns.ESTADO_CARGA}
      label="Estado carga"
      type="text"
    />
  ),
  [BusinessColumns.NRO_OMS]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-nro-oms-1"
      formik={formik}
      fieldName={BusinessColumns.NRO_OMS}
      label="Nro OMS"
      type="number"
    />
  ),
  [BusinessColumns.NRO_ORDEN]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-nro-orden-1"
      formik={formik}
      fieldName={BusinessColumns.NRO_ORDEN}
      label="Nro orden"
      type="text"
    />
  ),
  [BusinessColumns.FECHA_CUMP]: (formik: FormikValues) => (
    <MobileDatePicker
      label="Fecha cump"
      format="DD/MM/YYYY"
      sx={{ minWidth: "100%" }}
      key="text-fc-cum-1"
      defaultValue={dayjs(formik.values[BusinessColumns.FECHA_CUMP])}
      onChange={(v) =>
        formik.setFieldValue([BusinessColumns.FECHA_CUMP], v, true)
      }
    />
  ),
  [BusinessColumns.INFORME_TOA]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-inf-toa-1"
      formik={formik}
      fieldName={BusinessColumns.INFORME_TOA}
      label="Informe toa"
      type="text"
    />
  ),
  [BusinessColumns.FECHA_SAT]: (formik: FormikValues) => (
    <MobileDatePicker
      label="Fecha sat"
      format="DD/MM/YYYY"
      sx={{ minWidth: "100%" }}
      key="text-fc-sat-1"
      defaultValue={dayjs(formik.values[BusinessColumns.FECHA_SAT])}
      onChange={(v) =>
        formik.setFieldValue([BusinessColumns.FECHA_SAT], v, true)
      }
    />
  ),
  [BusinessColumns.PORCENTAGE]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-perc-1"
      formik={formik}
      fieldName={BusinessColumns.PORCENTAGE}
      label="Porcentage"
      type="number"
    />
  ),
  [BusinessColumns.TEL_SERVICIO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-tel-serv-1"
      formik={formik}
      fieldName={BusinessColumns.TEL_SERVICIO}
      label="Tel servicio"
      type="text"
    />
  ),
  [BusinessColumns.RECURSO]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-recurso-1"
      formik={formik}
      fieldName={BusinessColumns.RECURSO}
      label="Recurso"
      type="text"
    />
  ),
  [BusinessColumns.LINEA]: (formik: FormikValues) => (
    <FormikBusinessTextField
      key="text-linea-1"
      formik={formik}
      fieldName={BusinessColumns.LINEA}
      label="Linea"
      type="text"
    />
  ),
};
