export const langData: { [key: string]: { en: string; es: string } } = {
  Welcome: {
    en: "Welcome",
    es: "Hola",
  },  
  Search: {
    en: "Search",
    es: "Buscar",
  },
  HideColumn: {
    en: "Hide column",
    es: "Ocultar",
  },
  ShowColumns: {
    en: "Show hiden columns",
    es: "Mostrar columnas ocultas",
  },
  Filter: {
    en: "Filter",
    es: "Filtrar",
  },
  FilterBy: {
    en: "Filter by",
    es: "Filtro por",
  },
  CreatedAt: {
    en: "Creation Date",
    es: "Fecha",
  },
  UpdatedAt: {
    en: "Last Modified",
    es: "Fecha Modif.",
  },
  Creator: {
    en: "Creator",
    es: "Creador",
  },
  LastEditor: {
    en: "Editor",
    es: "Editor",
  },
  Actions: {
    en: "Actions",
    es: "Acciones",
  },
  Status: {
    en: "Status",
    es: "Estado",
  },
  Statuses: {
    en: "Statuses",
    es: "Estados",
  },
  Users: {
    en: "Users",
    es: "Usuarios",
  },
  Sheets: {
    en: "Sheets",
    es: "Planillas",
  },
  Sheet: {
    en: "Sheet",
    es: "Planilla",
  },
  AdminPanel: {
    en: "Admin Panel",
    es: "Panel Admin",
  },
  SheetNotAvailable: {
    en: "Sheet not available",
    es: "Planilla no disponible",
  },
  StatusNotAvailable: {
    en: "Status not available",
    es: "Estado no disponible",
  },
  EditRecord: {
    en: "Edit Record in",
    es: "Editar Registro en",
  },
  CreateRecord: {
    en: "Create Record in",
    es: "Crear Registro en",
  },
  NoRecordsFound: {
    en: "No records found",
    es: "No hay registros",
  },
  UnexpectedError: {
    en: "An unexpected error occurred. Please retry in a few seconds.",
    es: "Ocurrio un error inesperado. Por favor, reintente en unos segundos.",
  },
  Name: {
    en: "Name",
    es: "Nombre",
  },
  CreatorName: {
    en: "Creator Name",
    es: "Nombre Creador",
  },
  LastName: {
    en: "Last Name",
    es: "Apellido",
  },
  CreatorLastName: {
    en: "Creator Last Name",
    es: "Apellido Creador",
  },
  Phone: {
    en: "Phone",
    es: "Telefono",
  },
  CreatorPhone: {
    en: "Creator Phone",
    es: "Telefono Vendedor",
  },
  GovId: {
    en: "GovId",
    es: "DNI",
  },
  CreatorGovId: {
    en: "Creator GovId",
    es: "DNI Vendedor",
  },
  Group: {
    en: "Group",
    es: "Grupo",
  },
  CreatorGroup: {
    en: "Creator Group",
    es: "Grupo Vendedor",
  },
  Picture: {
    en: "Picture",
    es: "Foto",
  },
  Active: {
    en: "Active",
    es: "Activo",
  },
  Yes: {
    en: "Yes",
    es: "Si",
  },
  Role: {
    en: "Role",
    es: "Rol",
  },
  InactiveSince: {
    en: "Inactive Since",
    es: "Inactivo Desde",
  },
  all: {
    en: "all",
    es: "todas",
  },
  OnlyOwnRecords: {
    en: "Only Own Records",
    es: "Solo Registros Propios",
  },
  EditOnly: {
    en: "Edit Only",
    es: "Solo Editar",
  },
  VisibleColumns: {
    en: "Visible Columns",
    es: "Columnas Visibles",
  },
  EditableColumns: {
    en: "Editable Columns",
    es: "Columnas Editables",
  },
  VisibleSheets: {
    en: "VisibleSheets",
    es: "Planillas Visibles",
  },
  Derivations: {
    en: "Derivations",
    es: "Derivaciones",
  },
  FilterByMonth: {
    en: "Filter by Month",
    es: "Filtro por Mes",
  },
  January: {
    en: "January",
    es: "Enero",
  },
  February: {
    en: "February",
    es: "Febrero",
  },
  March: {
    en: "March",
    es: "Marzo",
  },
  April: {
    en: "April",
    es: "Abril",
  },
  May: {
    en: "May",
    es: "Mayo",
  },
  June: {
    en: "June",
    es: "Junio",
  },
  July: {
    en: "July",
    es: "Julio",
  },
  August: {
    en: "August",
    es: "Agosto",
  },
  September: {
    en: "September",
    es: "Septiembre",
  },
  October: {
    en: "October",
    es: "Octubre",
  },
  November: {
    en: "November",
    es: "Noviembre",
  },
  December: {
    en: "December",
    es: "Diciembre",
  },
};
