import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from "@material-ui/core";
import { List, ListSubheader, Menu } from "@material-ui/core";
import { lang } from "../../lang";

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    marginBottom: theme.spacing(2),
    width: "180px",
  },
  inputLabel: {
    marginRight: theme.spacing(1),
  },
}));

const monthOptions = [
  { value: "January", label: lang("January") },
  { value: "February", label: lang("February") },
  { value: "March", label: lang("March") },
  { value: "April", label: lang("April") },
  { value: "May", label: lang("May") },
  { value: "June", label: lang("June") },
  { value: "July", label: lang("July") },
  { value: "August", label: lang("August") },
  { value: "September", label: lang("September") },
  { value: "October", label: lang("October") },
  { value: "November", label: lang("November") },
  { value: "December", label: lang("December") },
];

export const monthsMap = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

interface MonthFilterProps {
  anchor: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
  setSelection: (months: string[]) => void;
}

const MonthFilter = (props: MonthFilterProps) => {
  const classes = useStyles();
  // all selected by default
  const [selectedMonths, setSelectedMonths] = useState<string[]>(
    monthOptions.map((m) => m.value)
  );

  const toggleMonth = (month: string) => {
    let selection: string[];
    if (selectedMonths.includes(month)) {
      selection = selectedMonths.filter((m) => m !== month);
    } else {
      selection = [...selectedMonths, month];
    }
    setSelectedMonths(selection);
    props.setSelection(selection);
  };

  return (
    <Menu
      anchorEl={props.anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      getContentAnchorEl={null}
      id={"filter-menu"}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={props.open}
      onClose={props.handleClose}
    >
      <div className={classes.selectContainer}>
        <List
          style={{
            width: "100%",
            maxWidth: 360,
            backgroundColor: "background.paper",
          }}
          subheader={<ListSubheader>{lang("FilterByMonth")}</ListSubheader>}
        >
          {monthOptions.map((option) => (
            <ListItem key={option.value}>
              <ListItemText primary={option.label} />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={selectedMonths.includes(option.value)}
                  onChange={() => toggleMonth(option.value)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </Menu>
  );
};

export default MonthFilter;
