import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDelete = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
      <DialogContent>
        <Typography variant="body1" id="delete-dialog-description">
          Are you sure you want to delete this record?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button key={"cancel-btn-1"} onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button
          key={"delete-btn-2"}
          onClick={props.onConfirm}
          color="secondary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDelete;
