import React, { useEffect, useState } from "react";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  List,
  ListSubheader,
  Menu,
  makeStyles,
} from "@material-ui/core";
import { FilterEntry } from "../../types";
import { formatColumnName } from "../../utils/formatters";
import { lang } from "../../lang";

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    marginBottom: theme.spacing(2),
    width: "220px",
  },
  inputLabel: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  field: string;
  options: FilterEntry[];
  anchor: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
  setSelection: (ids: number[]) => void;
}

const RecordsFilter = (props: Props) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

  // all selected by default
  useEffect(() => {
    const allOptions = props.options.map((u) => u.value) || [];
    setSelectedOptions(allOptions);
  }, [props.options]);

  const toggleOption = (opt: number) => {
    let selection: number[];
    if (selectedOptions.includes(opt)) {
      selection = selectedOptions.filter((o) => o !== opt);
    } else {
      selection = [...selectedOptions, opt];
    }
    setSelectedOptions(selection);
  };

  // Apply the selection changes,
  // call setSelection in parent component
  useEffect(() => {
    props.setSelection(selectedOptions);
  }, [selectedOptions]);

  return (
    <Menu
      anchorEl={props.anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      getContentAnchorEl={null}
      id={`filter-menu-${props.field}`}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={props.open}
      onClose={props.handleClose}
    >
      <div className={classes.selectContainer}>
        <List
          style={{
            width: "100%",
            maxWidth: 260,
            backgroundColor: "background.paper",
          }}
          subheader={
            <ListSubheader>{`${lang("FilterBy")} ${formatColumnName(
              props.field
            )}`}</ListSubheader>
          }
        >
          {props.options.map((option, i: number) => (
            <ListItem key={`li-${option.value}-${i}`}>
              <ListItemText primary={option.label} />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => toggleOption(option.value)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </Menu>
  );
};

export default RecordsFilter;
