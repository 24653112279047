import { CSSProperties } from "@material-ui/core/styles/withStyles";
import theme from "../theme";

type ListStyle = {
  rootContainer: CSSProperties;
  container: CSSProperties;
  button: CSSProperties;
  selectContainer: CSSProperties;
  select: CSSProperties;
  inputLabel: CSSProperties;
  sendIcon: CSSProperties;
  columnBig: CSSProperties;
  columnMedium: CSSProperties;
  columnSmall: CSSProperties;
  columnFixed: CSSProperties;
  columnFixedBig: CSSProperties;
  hideColumnIcon: CSSProperties;
};

export const listStyle: ListStyle = {
  rootContainer: {
    minHeight: "90vh",
  },
  container: {
    margin: "20px",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  button: {
    marginRight: "10px",
    marginBottom: "10px",
  },
  selectContainer: {
    display: "inline-flex",
    alignItems: "center",
    padding: "0.5rem",
    border: "1px solid #ccc",
    borderRadius: "4px",
    height: "3.5rem",
  },
  select: {
    display: "inline-flex",
    alignItems: "center",
    minWidth: "7rem",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  inputLabel: {
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
  },
  sendIcon: {
    marginLeft: "0.2rem",
  },
  columnBig: {
    minWidth: 180,
    maxWidth: 220,
    wordWrap: "break-word",
    wordBreak: "break-all",
  },
  columnMedium: {
    minWidth: 140,
    maxWidth: 200,
  },
  columnSmall: {
    minWidth: 110,
    maxWidth: 150,
  },
  columnFixed: {
    minWidth: 175,
    maxWidth: 175,
    wordWrap: "break-word",
    wordBreak: "break-all",
  },
  columnFixedBig: {
    minWidth: 195,
    maxWidth: 195,
    wordWrap: "break-word",
    wordBreak: "break-all",
  },
  hideColumnIcon: {
    color: "rgba(0, 0, 0, 0.05)",
    "&:hover": {
      color: "gray",
    },
    transition: 'color 0.3s ease', 
  },
};
