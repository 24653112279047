import axios from "axios";

export enum ApiPath {
  PASSWORD_LOGIN = "/auth/password-login",
  GOOGLE_LOGIN = "/auth/google-login",
  TOKEN_VALIDATION = "/auth/token",
  SHEETS = "/sheets",
  STATUS = "/statuses",
  USERS = "/users",
  ROLES = "/roles",
  BUSINESS_RECORDS = "/business/records",
  BUSINESS_COLUMNS = "/business/columns",
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:5000",
  headers: {
    "Content-Type": "application/json",
  },
});

// use JWT token for requests
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default apiClient;
