import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";

interface Props {
  onClick: () => void;
}

const DownloadButton = (props: Props) => {
  return (
    <Tooltip title="Download to excel spreadsheet">
      <IconButton
        key={"tc-dwnload-btn"}
        aria-label="dwnload"
        onClick={props.onClick}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DownloadButton;
