import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { businessFields } from "./business";
import { userFields, userInitialValues, userValidationSchema } from "./user";
import { AdminSection } from "../../types";
import { roleFields, roleInitialValues, roleValidationSchema } from "./role";
import {
  statusFields,
  statusInitialValues,
  statusValidationSchema,
} from "./status";
import {
  sheetFields,
  sheetInitialValues,
  sheetValidationSchema,
} from "./sheet";

export const FormFields = {
  ...businessFields,
};

// ===============================
//      ADMIN section fields
// ===============================
export const adminFormFields = {
  [AdminSection.Users]: userFields,
  [AdminSection.Status]: statusFields,
  [AdminSection.Sheets]: sheetFields,
  [AdminSection.Roles]: roleFields,
};

export const adminValSchema = {
  [AdminSection.Users]: userValidationSchema,
  [AdminSection.Status]: statusValidationSchema,
  [AdminSection.Sheets]: sheetValidationSchema,
  [AdminSection.Roles]: roleValidationSchema,
};

export const adminInitialVals = {
  [AdminSection.Users]: userInitialValues,
  [AdminSection.Status]: statusInitialValues,
  [AdminSection.Sheets]: sheetInitialValues,
  [AdminSection.Roles]: roleInitialValues,
};

// ===============================
//        Form styles
// ===============================

export const formStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: "900px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "1rem auto",
      padding: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    title: {
      marginBottom: theme.spacing(5),
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
      width: "100%",
    },
    formControlAuto: {
      margin: theme.spacing(2),
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    buttonCont: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    button: {
      margin: theme.spacing(2),
    },
    rndButton: {
      margin: theme.spacing(2),
      borderRadius: "50%",
      width: "60px",
      height: "60px",
    },
    invisible: {
      visibility: "hidden",
    },
  })
);
