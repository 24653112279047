import React, { useState } from "react";
import {
  styled,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  MenuItem,
  Fab,
  Menu,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import { IRole, IUser, Path, SheetsMap } from "../../types";
import { logout } from "../../services/api/auth";
import TemporaryDrawer from "./TemporaryDrawer";
import { getStoredItem } from "../../state/localstorage";

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

interface Props {
  role: IRole;
  sheets: SheetsMap;
  addBtn: boolean;
  section?: string;
}

export default function PrimaryAppBar(props: Props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const user = getStoredItem("user") as IUser;

  const handleRightMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate(Path.ROOT);
  };

  const handleAddClick = () => {
    const path = props.section
      ? `/${props.section}${Path.CREATE}`
      : Path.CREATE;
    // ATM can only create records with initial status
    navigate(path, {
      state: {
        role: props.role,
        visibleSheets: props.sheets,
      },
    });
  };

  const menuId = "primary-search-account-menu";
  const renderRightMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <TemporaryDrawer visibleSheets={props.sheets} role={props.role} />
          <img
            src="/static/TBG-logo.png"
            alt="Logo"
            style={{ width: "60px", height: "35px" }}
          />
          {props.addBtn && (
            <StyledFab color="secondary" aria-label="add">
              <AddIcon onClick={handleAddClick} />
            </StyledFab>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Open settings">
            <IconButton onClick={handleRightMenuOpen}>
              <Avatar alt={user.name || "NN"} src={user.picture || ""} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {renderRightMenu}
    </Box>
  );
}
