import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Box,
  Checkbox,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {
  BusinessColumns,
  Order,
  apiServices,
  AdminEntity,
  AdminSectionIdCol,
  Path,
  GenericMap,
  ISheet,
  IStatus,
  ADMIN_ROLE_ID,
  AdminSection,
  IRole,
  ADMIN_ROLE,
  AdminSectionFilterField,
  UserColumns,
  FilterEntry,
} from "../../types";
import { filterRecords, getComparator, pageIsOutOfBounds, stableSort } from "../../utils";
import { getStoredItem, refreshStoredState } from "../../state/localstorage";
import {
  firstLetterToUpperCase,
  formatColumnName,
} from "../../utils/formatters";
import { visuallyHidden } from "@mui/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableToolbar from "../../components/table/TableToolbar";
import { Tooltip } from "@mui/material";
import MonthFilter from "../../components/table/MonthFilter";
import GoBackButton from "../../components/btn/GoBackButton";
import { defaultFormatter, formatters } from "../../utils/formatters";
import ButtonContainer from "../../components/btn/ButtonContainer";
import SuccessSnackbar from "../../components/form/SuccessSnackbar";
import ErrorMsg from "../../components/table/ErrorMsg";
import NoRecordsRow from "../../components/table/NoRecordsRow";
import { lang } from "../../lang";
import { usePaginationStorage, useRoles } from "../../hooks";
import RecordsFilter from "../../components/table/RecordsFilter";
import { listStyle } from "../../styles";
import * as _ from "lodash";
import BasePage from "../../components/page/BasePage";

type SortingField = BusinessColumns.CREATED_AT;
type FilterField = BusinessColumns.CREATED_AT;

// user defined type guard
function isSortingField(field: string): field is SortingField {
  return [BusinessColumns.CREATED_AT].indexOf(field as BusinessColumns) !== -1;
}

function isFilterField(field: string): field is FilterField {
  return (
    [BusinessColumns.CREATED_AT, ...AdminSectionFilterField].indexOf(
      field as BusinessColumns
    ) !== -1
  );
}

function isSortingAndFilterField(field: string): boolean {
  return isSortingField(field) && isFilterField(field);
}

const useStyles = makeStyles(() => listStyle);

const AdminListPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { section } = useParams();

  const [error, setError] = useState("");
  const [records, setRecords] = useState<AdminEntity[]>([]);
  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const [columns, setColumns] = useState<string[]>([]);

  // Bulk Delete
  const [isBulkDeleteDialogOpen, setIsBulkDeleteDialogOpen] = useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);

  // Pagination
  const { page, setPage, rowsPerPage, setRowsPerPage } = usePaginationStorage(
    0,
    10,
    section
  );
  const [totalRecords, setTotalRecords] = useState<number>(0);

  // Filtering
  const [activeMonths, setActiveMonths] = useState<string[]>([]);
  const [filterSelection, setFilterSelections] = useState<{
    [key: string]: number[];
  }>({});
  const [filterAnchors, setFilterAnchors] = useState<{
    [key: string]: HTMLElement | null;
  }>({});
  const [searchQuery, setSearchQuery] = useState("");

  // Sorting
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<SortingField>(
    BusinessColumns.CREATED_AT
  );

  const [loading, setLoading] = useState(false);

  const [monthFilterAnchorEl, setMonthFilterAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMonthFilterVisible = Boolean(monthFilterAnchorEl);

  if (!section) {
    navigate(Path.ROOT);
  }

  const tableName = formatColumnName(section as string);
  const idCol = AdminSectionIdCol[section as AdminSection];
  const idColumns = Object.values(AdminSectionIdCol);
  const apiService = apiServices[section as AdminSection];

  // get status and roles for the forms
  const sheetsMap = getStoredItem(AdminSection.Sheets) as GenericMap<ISheet>;
  const statusesMap = getStoredItem(AdminSection.Status) as GenericMap<IStatus>;
  // this is set on login when user is admin
  // if not present, redirect to main menu
  if (_.isEmpty(sheetsMap) || _.isEmpty(statusesMap)) {
    navigate(Path.ROOT);
  }

  const handleOpenFilter = (
    field: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    // Create a copy of the filterStates object and toggle the filter state
    const updatedAnchors = { ...filterAnchors };
    updatedAnchors[field] = event.currentTarget;
    setFilterAnchors(updatedAnchors);
  };

  const handleCloseFilter = (field: string) => {
    // Create a copy of the filterStates object and toggle the filter state
    const updatedAnchors = { ...filterAnchors };
    updatedAnchors[field] = null;
    setFilterAnchors(updatedAnchors);
  };

  const handleFilterSelection = (field: string, selectedOptions: number[]) => {
    // Update the filterSelections state with the selected options for the given filter
    const updatedFilterSelections = { ...filterSelection };
    updatedFilterSelections[field] = selectedOptions;
    setFilterSelections(updatedFilterSelections);
  };

  // Get the roles
  // we'll need these for the filter options
  // in users page
  const { roles, error: rolesApiError } = useRoles();
  if (rolesApiError) {
    setError(rolesApiError);
  }

  const filterOptions: Record<string, FilterEntry[]> = useMemo(
    () => ({
      [UserColumns.ROLE]: roles.map((r: IRole) => ({
        value: r.roleId,
        label: firstLetterToUpperCase(r.name),
      })),
    }),
    [roles]
  );

  const handleEditClick = (id: number) => {
    navigate(`/${section}/${id}`);
  };

  const closeBulkDeleteDialog = () => {
    setIsBulkDeleteDialogOpen(false);
  };

  const handleBulkDeleteClick = async () => {
    setIsBulkDeleteDialogOpen(true);
  };

  const confirmBulkDelete = async () => {
    try {
      const deleteCount = selected.length;
      if (deleteCount > 0) {
        await apiService?.bulkDelete(selected);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const updatedRecords = records.filter(
          (r: any) => !selected.includes(r[idCol])
        );
        setRecords(updatedRecords);
        setTotalRecords(totalRecords - deleteCount);
        setSelected([]);
        // when deleteting status and roles need to update the local storage
        await refreshStoredState(section as AdminSection);
      }
    } catch (error: any) {
      console.log(error.response?.data?.message);
      setError(error.response?.data?.message || lang("UnexpectedError"));
    }
    closeBulkDeleteDialog();
  };

  useEffect(() => {
    fetchRecords();
    setFetchedData(true);
  }, [section, fetchedData]);

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const res = await apiService.getAll();
      setRecords(res);
      const totalRecords = res.length;
      setTotalRecords(totalRecords);

      if (totalRecords > 0) {
        // don't show all ids columns
        const cols = Object.keys(res[0]).filter(
          (k) => !idColumns.includes(k as any)
        );
        setColumns(cols);
      }
      // if the cached page is out of bounds,
      // reset it to be the first page
      if (pageIsOutOfBounds(page, totalRecords, rowsPerPage)) setPage(0);
    } catch (error: any) {
      console.log(error.response?.data?.message);
      setError(error.response?.data?.message || lang("UnexpectedError"));
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value);
    const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const toggleSort = (field: SortingField) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const newSelected = idCol && visibleRows.map((n) => n[idCol]);
      setSelected(newSelected as number[]);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleMonthFilterClose = () => {
    setMonthFilterAnchorEl(null);
  };

  const handleMonthFilterOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMonthFilterAnchorEl(event.currentTarget);
  };

  // filtering and sorting is handled on front end
  const visibleRows = useMemo(() => {
    let rows = filterRecords<AdminEntity>(
      records,
      section === AdminSection.Users ? filterSelection : {}, // only filters applied in User section
      activeMonths,
      undefined,
      searchQuery
    );
    setTotalRecords(rows.length);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    rows = stableSort<AdminEntity>(
      rows,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return rows;
  }, [
    records,
    order,
    orderBy,
    page,
    rowsPerPage,
    totalRecords,
    activeMonths,
    filterSelection,
    searchQuery,
  ]);

  // when navigating between sections
  // the visibleRows of previous table will remain till
  // the new records are fetched.
  // This flag is used to avoid rendering errors
  // when this happens
  const currentRecordId = visibleRows.length && Object.keys(visibleRows[0])[0];
  return (
    <BasePage
      key={`${section || "default"}-page`}
      role={ADMIN_ROLE as IRole}
      sheetsMap={sheetsMap}
      addBtn={true}
      section={section}
    >
      <SuccessSnackbar
        open={location.state?.successfulOp || false}
        action={location.state?.opAction}
      />
      <MonthFilter
        anchor={monthFilterAnchorEl}
        open={isMonthFilterVisible}
        handleClose={handleMonthFilterClose}
        setSelection={setActiveMonths}
      />
      {AdminSectionFilterField.map((f: any, i: number) => (
        <RecordsFilter
          key={`uf-${f}-${i}`}
          field={f}
          options={filterOptions[f] || []}
          anchor={filterAnchors[f]}
          open={Boolean(filterAnchors[f])}
          handleClose={() => handleCloseFilter(f)}
          setSelection={(selection: number[]) =>
            handleFilterSelection(f, selection)
          }
        />
      ))}
      <TableContainer component={Paper} className={classes.container}>
        <TableToolbar
          tableName={firstLetterToUpperCase(tableName)}
          numSelected={selected.length}
          isDeleteDialogOpen={isBulkDeleteDialogOpen}
          onDeleteClick={handleBulkDeleteClick}
          closeDeleteDialog={closeBulkDeleteDialog}
          confirmDelete={confirmBulkDelete}
          onSearch={setSearchQuery}
        />
        {error && <ErrorMsg error={error} />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    selected.length > 0 && selected.length < visibleRows.length
                  }
                  checked={
                    visibleRows.length > 0 &&
                    selected.length === visibleRows.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              {columns.map((c, i) => {
                if (isSortingAndFilterField(c)) {
                  return (
                    <TableCell
                      key={`tc-${i}`}
                      sortDirection={orderBy === c ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === c}
                        direction={orderBy === c ? order : "asc"}
                        onClick={() => toggleSort(c as SortingField)}
                      >
                        {formatColumnName(c)}
                        {orderBy === c ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      <Tooltip title="Filter">
                        <IconButton onClick={handleMonthFilterOpen}>
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  );
                } else if (isSortingField(c)) {
                  return (
                    <TableCell
                      key={`tc-${i}`}
                      sortDirection={orderBy === c ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === c}
                        direction={orderBy === c ? order : "asc"}
                        onClick={(c) =>
                          toggleSort(c as unknown as SortingField)
                        }
                      >
                        {formatColumnName(c)}
                        {orderBy === c ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else if (isFilterField(c)) {
                  return (
                    <TableCell
                      key={`tc-${i}`}
                      sortDirection={orderBy === c ? order : false}
                    >
                      {formatColumnName(c)}
                      <Tooltip title={lang("Filter")}>
                        <IconButton
                          onClick={(event) => handleOpenFilter(c, event)}
                        >
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  );
                }

                return (
                  <TableCell key={`tc-${i}`}>{formatColumnName(c)}</TableCell>
                );
              })}
              <TableCell key={"tc-a"}>{lang("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody key={`${section || "default"}-tb`}>
            {loading && (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
            {!totalRecords && <NoRecordsRow />}
            {!loading &&
              currentRecordId == idCol &&
              visibleRows.map((record: any, i: number) => {
                const recordId = record[idCol];
                const isItemSelected = isSelected(recordId);
                const labelId = `enhanced-table-checkbox-${i}`;
                return (
                  <TableRow
                    hover
                    key={`tr-${section}-${recordId}`}
                    onClick={(event) => handleClick(event, recordId)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {columns.map((c, i) => {
                      const formatter = formatters[c] || defaultFormatter;
                      return (
                        <TableCell
                          key={`tc-${c}-${i}`}
                          className={classes[formatter.column]}
                        >
                          {formatter.value(record[c], {
                            [AdminSection.Sheets]: sheetsMap,
                            [AdminSection.Status]: statusesMap,
                          })}
                        </TableCell>
                      );
                    })}

                    <TableCell key={"tc-avalable-actions"} align="center">
                      <Grid container direction="row" alignItems="center">
                        {recordId === ADMIN_ROLE_ID &&
                        section === AdminSection.Roles ? (
                          <></>
                        ) : (
                          <IconButton
                            key={"tc-edit-btn"}
                            onClick={() => handleEditClick(recordId)}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <table>
          <tbody>
            <tr>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <ButtonContainer>
        <GoBackButton />
      </ButtonContainer>
    </BasePage>
  );
};

export default AdminListPage;
