import { io, Socket } from "socket.io-client";

export const wsLogin = (token: string): Socket => {
  const socket = io(process.env.REACT_APP_WSS_URL || "ws://localhost:5000", {
    query: { token }, // Send the token for authentication
  });

  return socket;
};

export const wsLogout = (socket?: Socket | null) => {
  socket && socket.disconnect();
};
