import React from "react";
import * as Yup from "yup";
import {
  AdminEntities,
  ISheet,
  SheetColumns,
  StatusColumns,
} from "../../types";
import FormikTextField from "./FormikTextField";
import { formatColumnName } from "../../utils/formatters";
import CheckboxListEntity from "./CheckboxListEntity";

export const sheetInitialValues: Partial<ISheet> = {
  name: "",
  statuses: [],
};

export const sheetValidationSchema = Yup.object({
  [SheetColumns.NAME]: Yup.string().required("Required"),
  [SheetColumns.STATUSES]: Yup.array().of(Yup.object()),
});

export const sheetFields = {
  [SheetColumns.NAME]: (formik: any) => (
    <FormikTextField
      key={"txt-name-1"}
      formik={formik}
      fieldName={SheetColumns.NAME}
      label={formatColumnName(SheetColumns.NAME)}
    />
  ),
  [SheetColumns.STATUSES]: (formik: any, entities: AdminEntities) => {
    // Only status that are not linked to a sheet should show here
    // each status can only be visible in one sheet at a time
    const statuses = entities.statuses.filter((s) => !s.sheetId);
    statuses.unshift(...formik.values[SheetColumns.STATUSES]);

    if (!statuses.length) {
      return;
    }
    return (
      <CheckboxListEntity
        key={"chk-box-ls-st"}
        formik={formik}
        field={SheetColumns.STATUSES}
        label={formatColumnName(SheetColumns.STATUSES)}
        options={statuses}
        idCol={StatusColumns.ID}
      />
    );
  },
};
