import React from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, Tooltip } from "@material-ui/core";
import { lang } from "../../lang";

interface Props {
  classes: any,
  toggleVisibility: () => void;
}

const HideColumnButton = (props: Props) => (
  <Tooltip title={lang("HideColumn")}>
    <IconButton
      className={props.classes.hideColumnIcon}
      onClick={props.toggleVisibility}
    >
      <VisibilityOffIcon sx={{ fontSize: "1rem" }} />
    </IconButton>
  </Tooltip>
);

export default HideColumnButton;
