import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Box,
  Checkbox,
  makeStyles,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {
  IBusiness,
  IRole,
  ADMIN_ROLE_ID,
  BusinessColumns,
  Path,
  ISheet,
  hiddedColumns,
  alwaysVisibleBusinessCols,
  SortingField,
  isSortingField,
  isFilterField,
  isSortingAndFilterField,
  UserFilterField,
  userMetaColumns,
  AdminSection,
} from "../types";
import {
  defaultSeparator,
  getComparator,
  getUsersMap,
  filterRecords,
  stableSort,
  pageIsOutOfBounds,
} from "../utils";
import {
  firstLetterToUpperCase,
  formatColumnName,
  formatCreatorColumnName,
  parseNameToPath,
} from "../utils/formatters";
import { visuallyHidden } from "@mui/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableToolbar from "../components/table/TableToolbar";
import MonthFilter from "../components/table/MonthFilter";
import RecordsFilter from "../components/table/RecordsFilter";
import GoBackButton from "../components/btn/GoBackButton";
import businessApi from "../services/api/business";
import {
  defaultFormatter,
  formatters,
  getXlsxFileName,
} from "../utils/formatters";
import ButtonContainer from "../components/btn/ButtonContainer";
import HideColumnButton from "../components/btn/HideColumnButton";
import SuccessSnackbar from "../components/form/SuccessSnackbar";
import { logout } from "../services/api/auth";
import { INITIAL_STATUS_ID } from "../components/form/business";
import NoRecordsRow from "../components/table/NoRecordsRow";
import ErrorMsg from "../components/table/ErrorMsg";
import { usePaginationStorage, useSortingStorage } from "../hooks";
import { lang } from "../lang";
import { listStyle } from "../styles";
import useColumnVisibility from "../hooks/columnVisibility";
import useBusinessWSEvents from "../hooks/websocket/businessEvents";
import { getStoredItem } from "../state/localstorage";
import BasePage from "../components/page/BasePage";

const useStyles = makeStyles(() => listStyle);

const ListPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { sheet } = useParams();

  const tablePath = sheet;
  let sheetId = location.state?.sheetId as number;
  let currentSheet = location.state?.sheet as ISheet;
  let tableName = location.state?.tableName as string;
  let role = location.state?.role as IRole;
  let visibleSheets = location.state?.visibleSheets;

  // In case the expected info is not passedin the state
  // (e.g. when typing directly the path)
  // fetch the required info from the local storage
  if (!visibleSheets) {
    visibleSheets = getStoredItem<ISheet>(AdminSection.Sheets);

    const roleStr = localStorage.getItem("role");
    role = roleStr && JSON.parse(roleStr);

    for (const id of Object.keys(visibleSheets)) {
      const s = visibleSheets[id];
      if (parseNameToPath(s.name) != tablePath) {
        continue;
      }
      sheetId = parseInt(id);
      currentSheet = s;
      tableName = s.name;
    }

    if (!visibleSheets || !role || !sheetId) {
      logout();
      navigate(Path.LOGIN);
    }
  }

  // keep only the columns for the table
  // e.i, remove the creatorId, editorId, etc
  let columns: string[] = [];
  if (role.visibleColumns === "*") {
    columns = Object.values(BusinessColumns);
  } else {
    columns = (role.visibleColumns as string).split(defaultSeparator);
    columns.unshift(...alwaysVisibleBusinessCols);
  }

  columns = columns.filter(
    (c) => !hiddedColumns.includes(c as BusinessColumns)
  );

  const [error, setError] = useState("");

  // Fetch data
  const [records, setRecords] = useState<IBusiness[]>([]);
  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  // Bulk Delete
  const [isBulkDeleteDialogOpen, setIsBulkDeleteDialogOpen] = useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);

  // Pagination - store user preferences for better ux
  const { page, setPage, rowsPerPage, setRowsPerPage } = usePaginationStorage(
    0,
    10,
    sheet
  );
  const [totalRecords, setTotalRecords] = useState<number>(0);

  // Column visibility
  const { visibleColumns, toggleColumnVisibility, resetColumnVisibility } =
    useColumnVisibility(columns, `${sheet}_visible_cols`);

  // Filtering
  const [userFilterSelection, setUserFilterSelections] = useState<{
    [key: string]: number[];
  }>({});
  const [filterAnchors, setFilterAnchors] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const [activeMonthsCreated, setActiveMonthsCreated] = useState<string[]>([]);
  const [activeMonthsUpdated, setActiveMonthsUpdated] = useState<string[]>([]);
  const [createdMonthFilterAnchorEl, setCreatedMonthFilterAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [updatedMonthFilterAnchorEl, setUpdatedMonthFilterAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleOpenUserFilter = (
    field: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    // Create a copy of the filterStates object and toggle the filter state
    const updatedAnchors = { ...filterAnchors };
    updatedAnchors[field] = event.currentTarget;
    setFilterAnchors(updatedAnchors);
  };

  const handleCloseUserFilter = (field: string) => {
    // Create a copy of the filterStates object and toggle the filter state
    const updatedAnchors = { ...filterAnchors };
    updatedAnchors[field] = null;
    setFilterAnchors(updatedAnchors);
  };

  const handleUserFilterSelection = (
    field: string,
    selectedOptions: number[]
  ) => {
    // Update the filterSelections state with the selected options for the given filter
    const updatedFilterSelections = { ...userFilterSelection };
    updatedFilterSelections[field] = selectedOptions;
    setUserFilterSelections(updatedFilterSelections);
  };

  const isCreatedMonthFilterVisible = Boolean(createdMonthFilterAnchorEl);
  const isUpdatedMonthFilterVisible = Boolean(updatedMonthFilterAnchorEl);

  // Sorting
  const { order, setOrder, orderBy, setOrderBy } = useSortingStorage(
    "asc",
    BusinessColumns.CREATED_AT
  );

  const handleEditClick = (id: number) => {
    navigate(`${Path.EDIT}/${id}`, {
      state: {
        id,
        sheet: visibleSheets[sheetId],
        tableName,
        tablePath,
        role,
        visibleSheets: visibleSheets,
      },
    });
  };

  const closeBulkDeleteDialog = () => {
    setIsBulkDeleteDialogOpen(false);
  };

  const handleBulkDeleteClick = async () => {
    setIsBulkDeleteDialogOpen(true);
  };

  const confirmBulkDelete = async () => {
    try {
      const deleteCount = selected.length;
      if (deleteCount > 0) {
        await businessApi.bulkDelete(selected);
        const updatedRecords = records.filter((r) => !selected.includes(r.id));
        setRecords(updatedRecords);
        setTotalRecords(totalRecords - deleteCount);
        setSelected([]);
      }
    } catch (error: any) {
      console.log(error.response?.data?.message);
      setError(error.response?.data?.message || lang("UnexpectedError"));
    }
    closeBulkDeleteDialog();
  };

  useEffect(() => {
    fetchRecords(sheetId);
    setFetchedData(true);
  }, [sheetId, fetchedData]);

  const fetchRecords = async (
    sheetId: number,
    page?: number,
    rowsPerPage?: number
  ) => {
    setLoading(true);
    try {
      const res = await businessApi.getAllBySheet(sheetId, page, rowsPerPage);
      setRecords(res.records);
      setTotalRecords(res.totalCount);
      // if the cached page is out of bounds, 
      // reset it to be the first page
      if (pageIsOutOfBounds(page || 0, res.totalCount, rowsPerPage || 10)) setPage(0)
    } catch (error: any) {
      console.log(error.response?.data?.message || error);
      setError(error.response?.data?.message || lang("UnexpectedError"));
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value);
    const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const toggleSort = (field: SortingField) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleCreatedMonthFilterClose = () => {
    setCreatedMonthFilterAnchorEl(null);
  };

  const handleCreatedMonthFilterOpen = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setCreatedMonthFilterAnchorEl(event.currentTarget);
  };

  const handleUpdatedMonthFilterClose = () => {
    setUpdatedMonthFilterAnchorEl(null);
  };

  const handleUpdatedMonthFilterOpen = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setUpdatedMonthFilterAnchorEl(event.currentTarget);
  };

  // filtering and sorting is handled on front end
  const visibleRows = useMemo(() => {
    let rows = filterRecords<IBusiness>(
      records,
      userFilterSelection,
      activeMonthsCreated,
      activeMonthsUpdated
    );
    rows = stableSort<IBusiness>(
      rows,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      getComparator(order, orderBy)
    );
    setTotalRecords(rows.length);
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [
    records,
    order,
    orderBy,
    page,
    rowsPerPage,
    totalRecords,
    activeMonthsCreated,
    activeMonthsUpdated,
    userFilterSelection,
  ]);

  const handleExportToExcel = () => {
    let rows = filterRecords(
      records,
      userFilterSelection,
      activeMonthsCreated,
      activeMonthsUpdated
    );
    rows = stableSort<IBusiness>(
      rows,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      getComparator(order, orderBy)
    );
    const formattedData = rows.map((record: any) => {
      // Format the data for each column as needed
      const data: any = {};
      for (const c of visibleColumns) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const formatter = formatters[c] || defaultFormatter;
        if (c == BusinessColumns.STATUS_ID) {
          data[formatColumnName("Status")] = formatter.value(
            record[c],
            currentSheet.statuses
          );
        } else {
          data[formatColumnName(c)] = formatter.value(record[c]);
        }
      }
      return data;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "buffer",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = getXlsxFileName();
    a.click();

    URL.revokeObjectURL(url);
  };

  const usersMap = useMemo(() => getUsersMap(records), [records]);

  // Subscribe to Business-related WebSocket events
  useBusinessWSEvents({ sheetId, setRecords, fetchRecords });

  return (
    <BasePage
      containerClassName={classes.rootContainer}
      role={role}
      sheetsMap={visibleSheets}
      addBtn={
        sheetId === INITIAL_STATUS_ID &&
        (role.roleId === ADMIN_ROLE_ID || !role.editOnly)
      }
    >
      <SuccessSnackbar
        open={location.state?.successfulOp || false}
        action={location.state?.opAction}
      />
      <MonthFilter
        anchor={createdMonthFilterAnchorEl}
        open={isCreatedMonthFilterVisible}
        handleClose={handleCreatedMonthFilterClose}
        setSelection={setActiveMonthsCreated}
      />
      <MonthFilter
        anchor={updatedMonthFilterAnchorEl}
        open={isUpdatedMonthFilterVisible}
        handleClose={handleUpdatedMonthFilterClose}
        setSelection={setActiveMonthsUpdated}
      />
      {UserFilterField.map(
        (f: any, i: number) =>
          !!usersMap[f] && (
            <RecordsFilter
              key={`uf-${f}-${i}`}
              field={f}
              options={usersMap[f] || []}
              anchor={filterAnchors[f]}
              open={Boolean(filterAnchors[f])}
              handleClose={() => handleCloseUserFilter(f)}
              setSelection={(selection: number[]) =>
                handleUserFilterSelection(f, selection)
              }
            />
          )
      )}
      <TableContainer component={Paper} className={classes.container}>
        <TableToolbar
          tableName={firstLetterToUpperCase(tableName)}
          numSelected={selected.length}
          isDeleteDialogOpen={isBulkDeleteDialogOpen}
          onDeleteClick={handleBulkDeleteClick}
          onDownloadClick={
            role.roleId === ADMIN_ROLE_ID ? handleExportToExcel : undefined
          }
          closeDeleteDialog={closeBulkDeleteDialog}
          confirmDelete={confirmBulkDelete}
          resetColumnsVisibility={
            visibleColumns.length < columns.length
              ? resetColumnVisibility
              : undefined
          }
        />
        {error && <ErrorMsg error={error} />}
        <Table>
          <TableHead>
            <TableRow>
              {role.roleId === ADMIN_ROLE_ID && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < visibleRows.length
                    }
                    checked={
                      visibleRows.length > 0 &&
                      selected.length === visibleRows.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                  />
                </TableCell>
              )}
              {visibleColumns.map((c, i) => {
                switch (c) {
                  case BusinessColumns.STATUS_ID:
                    return (
                      <TableCell
                        className={classes["columnMedium"]}
                        key={`tc-${i}`}
                      >
                        {lang("Status")}
                      </TableCell>
                    );
                  default:
                    break;
                }
                if (isSortingAndFilterField(c)) {
                  return (
                    <TableCell
                      className={classes["columnMedium"]}
                      key={`tc-${i}`}
                      sortDirection={orderBy === c ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === c}
                        direction={orderBy === c ? order : "asc"}
                        onClick={() => toggleSort(c as SortingField)}
                      >
                        {formatColumnName(c)}
                        {orderBy === c ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      <Tooltip title={lang("Filter")}>
                        <IconButton
                          onClick={
                            c === BusinessColumns.CREATED_AT
                              ? handleCreatedMonthFilterOpen
                              : handleUpdatedMonthFilterOpen
                          }
                        >
                          <FilterListIcon sx={{ fontSize: "1.2rem" }} />
                        </IconButton>
                      </Tooltip>
                      <HideColumnButton
                        classes={classes}
                        toggleVisibility={() => toggleColumnVisibility(c)}
                      />
                    </TableCell>
                  );
                } else if (isSortingField(c)) {
                  return (
                    <TableCell
                      className={classes["columnMedium"]}
                      key={`tc-${i}`}
                      sortDirection={orderBy === c ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === c}
                        direction={orderBy === c ? order : "asc"}
                        onClick={(c) =>
                          toggleSort(c as unknown as SortingField)
                        }
                      >
                        {formatColumnName(c)}
                        {orderBy === c ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      <HideColumnButton
                        classes={classes}
                        toggleVisibility={() => toggleColumnVisibility(c)}
                      />
                    </TableCell>
                  );
                } else if (isFilterField(c)) {
                  return (
                    <TableCell
                      className={classes["columnMedium"]}
                      key={`tc-${i}`}
                      sortDirection={orderBy === c ? order : false}
                    >
                      {formatColumnName(c)}
                      <Tooltip title={lang("Filter")}>
                        <IconButton
                          onClick={(event) => handleOpenUserFilter(c, event)}
                        >
                          <FilterListIcon sx={{ fontSize: "1.2rem" }} />
                        </IconButton>
                      </Tooltip>
                      <HideColumnButton
                        classes={classes}
                        toggleVisibility={() => toggleColumnVisibility(c)}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    className={classes["columnMedium"]}
                    key={`tc-${i}`}
                  >
                    {formatColumnName(c)}
                    <HideColumnButton
                      classes={classes}
                      toggleVisibility={() => toggleColumnVisibility(c)}
                    />
                  </TableCell>
                );
              })}

              {!role.onlyOwnRecords &&
                userMetaColumns.map((c, i) => (
                  <TableCell
                    className={classes["columnMedium"]}
                    key={`tc-creator-${i}`}
                  >
                    {formatCreatorColumnName(c)}
                  </TableCell>
                ))}
              <TableCell key={"tc-a"}>{lang("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
            {!totalRecords && <NoRecordsRow />}
            {!loading &&
              visibleRows.map((record: any, i: number) => {
                const isItemSelected = isSelected(record.id);
                const labelId = `enhanced-table-checkbox-${i}`;
                return (
                  <TableRow
                    hover
                    key={`tr-${record.id}`}
                    onClick={(event) =>
                      role.roleId === ADMIN_ROLE_ID &&
                      handleClick(event, record.id)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    {role.roleId === ADMIN_ROLE_ID && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    {visibleColumns.map((c, i) => {
                      const formatter = formatters[c] || defaultFormatter;
                      return (
                        <TableCell
                          key={`tc-${c}-${i}`}
                          className={classes[formatter.column]}
                        >
                          {formatter.value(record[c], currentSheet.statuses)}
                        </TableCell>
                      );
                    })}
                    {/* Business specific: display creator use metadata for roles
                      that can see all creators records
                    */}
                    {!role.onlyOwnRecords &&
                      userMetaColumns.map((c, i) => {
                        const formatter = formatters[c] || defaultFormatter;
                        return (
                          <TableCell
                            key={`tc-creator-${c}-${i}`}
                            className={classes[formatter.column]}
                          >
                            {formatter.value(
                              record.creator[c],
                              currentSheet.statuses
                            )}
                          </TableCell>
                        );
                      })}
                    <TableCell key={"tc-avalable-actions"} align="center">
                      <Grid container direction="row" alignItems="center">
                        {role.editableColumns.length && (
                          <IconButton
                            key={"tc-edit-btn"}
                            onClick={() => handleEditClick(record.id)}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <table>
          <tbody>
            <tr>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <ButtonContainer>
        <GoBackButton />
      </ButtonContainer>
    </BasePage>
  );
};

export default ListPage;
